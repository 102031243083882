/**
 * Generates a hash from a string.
 *
 * Taken from https://stackoverflow.com/a/8831937.
 */
export const simpleHash = (str: string) => {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    let chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};
