import { useEffect, useState } from 'react';
import { Button } from '@components/atoms/button/button';
import { IconClose } from '@components/atoms/icons';
import { simpleHash } from 'helpers/hash';
import { envSiteWideNotification } from 'helpers/public-env';

const SiteWideNotification = () => {
  const [show, setShow] = useState(false);
  const message = envSiteWideNotification();

  const hash = message ? simpleHash(message) : undefined;
  const storageKey = hash ? `notification_${hash}` : undefined;

  useEffect(() => {
    if (!message || !storageKey) {
      return;
    }
    const exists = !!localStorage.getItem(storageKey);
    setShow(!exists);
  }, [message, storageKey]);

  if (!message) {
    return <></>;
  }
  return (
    <div
      className={`relative bg-nad-blue text-white text-s text-center p-5 pr-12 ${show ? '' : 'hidden'}`}
      data-testid={'site-wide-notification'}
    >
      {message}
      <Button
        onClick={() => {
          if (storageKey) {
            setShow(false);
            localStorage.setItem(storageKey, '1');
          }
        }}
        className="absolute top-[26px] right-[20px]"
        variant="icon"
      >
        <IconClose className="w-[15px] h-[15px]" color="white" />
      </Button>
    </div>
  );
};

export default SiteWideNotification;
