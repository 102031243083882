import { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRouter } from 'next/router';
import Footer from '../organisms/footer/footer';
import Header from '../organisms/header/header';
import MobileNav from '@components/organisms/mobile-nav/mobile-nav';
import Logo from '@components/atoms/logo/logo';
import Message from '@components/molecules/message/message';
import { Text } from '@components/atoms/text/text';
import { GuestIdleTimer } from '@components/organisms/guest-idle-timer/guest-idle-timer';
import SiteWideNotification from '@components/molecules/site-wide-notification/site-wide-notification';

export const TemplateContainer = ({
  children,
  className = '',
  ...props
}: any) => {
  return (
    <div className={`flex flex-col h-full overflow-hidden ${className}`}>
      {children}
    </div>
  );
};

interface Props {
  children: ReactNode;
  extra?: ReactNode;
  className?: string;
  isContainer?: boolean;
  withMargin?: boolean;
}

export const SimpleTemplateContainer = ({
  children,
  className = '',
  withMargin = true,
  isContainer,
  extra,
  ...props
}: Props) => {
  const router = useRouter();
  const [showMobileNav, setShowMobileNav] = useState(false);
  return (
    <div
      className={`flex flex-col items-stretch min-h-screen relative`}
      {...props}
    >
      {router.query.sessionEnded !== undefined && (
        <div className="container mt-8">
          <Message variant="narrow">
            <FormattedMessage
              id="ZQDH3r"
              defaultMessage="Your guest session has ended."
              description="Message shown to a user when their session automatically times out after a period of inactivity (about 15 minutes)."
            />
          </Message>
        </div>
      )}
      <SiteWideNotification />
      <header role="banner" className="flex-none">
        <Header onMobileNavToggle={(state) => setShowMobileNav(state)} />
      </header>
      <main
        id="main-content"
        className={`${isContainer && 'container mx-auto'} ${
          withMargin && 'md:my-10 mb-10 '
        } ${className} `}
      >
        <GuestIdleTimer />
        {children}
      </main>
      <footer role="contentinfo" className="mt-auto">
        <Footer />
      </footer>
      {extra && extra}
      <MobileNav
        onMobileNavToggle={(state) => setShowMobileNav(state)}
        showMobileNav={showMobileNav}
      />
    </div>
  );
};

type VerticalAlignment = 'center' | 'top';

interface LogoProps {
  children: ReactNode;
  extra?: ReactNode;
  className?: string;
  vAlign?: VerticalAlignment;
}

interface OauthProps {
  children: ReactNode;
  extra?: ReactNode;
  className?: string;
  preAuthPassed: boolean;
  preAuthErrorMessage?: string;
}

export const OauthTemplateContainer = ({
  children,
  preAuthPassed,
  preAuthErrorMessage,
  ...props
}: OauthProps & LogoProps) => {
  return (
    <LogoTemplateContainer {...props}>
      {preAuthPassed && children}
      {!preAuthPassed && (
        <div className="container-xs mt-20 space-y-8">
          <Text size="L" color="nad-blue" className="text-center font-serif">
            <FormattedMessage
              id="nOfjzh"
              defaultMessage="We’re experiencing an internal problem. Please try again later."
              description="Oauth error message title shown when the pre auth check fails."
            />
          </Text>
          <Message
            type="danger"
            textAlign="center"
            data-testid="pre-auth-error"
          >
            {!!preAuthErrorMessage && preAuthErrorMessage}
            {!preAuthErrorMessage && (
              <FormattedMessage
                id="Y+BJby"
                defaultMessage="Your oAuth client is not configured properly."
                description="Generic oAuth error message when the pre auth check fails."
              />
            )}
          </Message>
        </div>
      )}
    </LogoTemplateContainer>
  );
};

export const LogoTemplateContainer = ({
  children,
  className = '',
  vAlign = 'center',
  ...props
}: LogoProps) => {
  const verticalClasses: Record<VerticalAlignment, string> = {
    center: 'justify-center',
    top: 'mt-12',
  };
  return (
    <div
      className={`flex flex-col items-center min-h-screen relative ${verticalClasses[vAlign]}`}
      data-testid="oauth-template"
    >
      <div className={`container mx-auto ${className}`}>
        <div className="mx-auto max-w-[300px] mb-8">
          <Logo />
        </div>
        {children}
      </div>
      {props.extra && props.extra}
    </div>
  );
};
