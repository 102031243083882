import { env } from 'next-runtime-env';

const get = (key: string) => {
  return env(key);
};

/**
 * Required to format the export like this so we can mock it in Cypress
 */
const res = {
  get,
};

export const envIsCanadianSite = () => {
  return res.get('NEXT_PUBLIC_SITE_COUNTRY_CODE') === 'CA';
};

export const envRequireCaptcha = () => {
  return res.get('NEXT_PUBLIC_REQUIRE_CAPTCHA') === 'true';
};

export const envRecaptchaSiteKey = () => {
  return res.get('NEXT_PUBLIC_RECAPTCHA_SITE_KEY');
};

export const envUseNewTreasurerPortal = () => {
  return res.get('NEXT_PUBLIC_USE_NEW_TREASURER_PORTAL') === 'true';
};

export const envTreasurerPortalUrl = () => {
  return res.get('NEXT_PUBLIC_URL_TREASURER_PORTAL');
};

export const envUseNewChurchEnrollment = () => {
  return res.get('NEXT_PUBLIC_USE_NEW_CHURCH_ENROLLMENT') === 'true';
};

export const envShow2fa = () => {
  return res.get('NEXT_PUBLIC_SHOW_2FA') === 'true';
};

export const envAllowTreasurerPortalMedia = () => {
  return res.get('NEXT_PUBLIC_ALLOW_TREASURER_PORTAL_MEDIA') === 'true';
};

export const envHelpScoutBeacon = (language: 'EN' | 'ES' | 'FR') => {
  return res.get(`NEXT_PUBLIC_HELPSCOUT_BEACON_${language}`);
};

export const envSiteWideNotification = () => {
  return res.get(`NEXT_PUBLIC_SITE_WIDE_NOTIFICATION`);
};

export default res;
