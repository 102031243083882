import '../styles/globals.scss';
import type { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import App from 'next/app';
import { useEffect } from 'react';
import messagesEnglish from 'lang-compiled/en.json';
import messagesSpanish from 'lang-compiled/es.json';
import messagesFrench from 'lang-compiled/fr.json';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';

import { SESSION_REFETCH_INTERVAL } from 'api/helpers/session';
import { PageMeta } from '@components/organisms/page-meta/page-meta';
import ErrorBoundary from '@components/organisms/error-boundary/error-boundary';
import JavascriptDisabled from '@components/molecules/javascript-disabled/javascript-disabled';
import { envHelpScoutBeacon } from 'helpers/public-env';

const loadLocaleData = (locale: string) => {
  switch (locale) {
    case 'es':
      return messagesSpanish;
    case 'fr':
      return messagesFrench;
    default:
      return messagesEnglish;
  }
};

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const defaultLocale = router.defaultLocale || 'en';
  const locale = router.locale || defaultLocale;
  const messages = loadLocaleData(locale);
  dayjs.locale(locale);

  useEffect(() => {
    window.Beacon('destroy');
    if (locale === 'fr') {
      window.Beacon('init', envHelpScoutBeacon('FR')!);
    } else if (locale === 'es') {
      window.Beacon('init', envHelpScoutBeacon('ES')!);
    } else {
      window.Beacon('init', envHelpScoutBeacon('EN')!);
    }
    window.Beacon('config', { display: { style: 'manual' } });
  }, [locale]);
  return (
    <IntlProvider
      messages={messages}
      locale={locale}
      defaultLocale={defaultLocale}
      defaultRichTextElements={{
        span: (text) => <span>{text}</span>,
        ul: (text) => <ul className="ml-6 list-disc">{text}</ul>,
        li: (text) => <li>{text}</li>,
        p: (text) => <p>{text}</p>,
        strong: (text) => <strong>{text}</strong>,
      }}
    >
      <PageMeta />
      <SessionProvider
        session={(pageProps as any).session}
        refetchInterval={SESSION_REFETCH_INTERVAL}
      >
        <a href="#main-content" className="sr-only focus:not-sr-only">
          <FormattedMessage
            id="6KyjJ9"
            defaultMessage="Skip to content"
            description="Link to skip to the main content of the page."
          />
        </a>
        <ErrorBoundary>
          <Component {...pageProps} />
          <JavascriptDisabled></JavascriptDisabled>
        </ErrorBoundary>
      </SessionProvider>
    </IntlProvider>
  );
}

MyApp.getInitialProps = async (context: any) => {
  const props = await App.getInitialProps(context);
  return props;
};

export default MyApp;
